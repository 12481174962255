import * as Sentry from '@sentry/sveltekit';
import { handleErrorWithSentry } from '@sentry/sveltekit';
import { dev } from '$app/environment';

Sentry.init({
	dsn: 'https://ac55f89629c3b495c0c719c08c7e35f8@o4506626928672768.ingest.us.sentry.io/4506626929786880',
	tunnel: '/api/sentryTunnel',
	tracesSampleRate: 1.0,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	integrations: [
		Sentry.browserProfilingIntegration(),
		Sentry.replayIntegration({
			maskAllText: false,
			blockAllMedia: false
		})
	],
	profilesSampleRate: 1.0,
	environment: dev ? 'development' : 'production'
});

export const handleError = handleErrorWithSentry();
