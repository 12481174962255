import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12')
];

export const server_loads = [2];

export const dictionary = {
		"/": [~4],
		"/(protected)/0/acquisitions/referrals/create": [~5,[2]],
		"/(protected)/0/home": [6,[2]],
		"/(protected)/0/team/accountabilityChart": [~7,[2]],
		"/(protected)/0/team/callins/create": [~8,[2]],
		"/auth/login": [9,[],[3]],
		"/auth/logout": [~10,[],[3]],
		"/getpaid": [~11],
		"/rentalAvailability": [~12]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';